import React from 'react';
import SkeletonCard from 'components/cards/skeleton';
import { Skeleton } from '@material-ui/lab';
import SkeletonFooter from 'components/footer/skeleton';

import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

export const SkeletonSupportHomeView = () => {
  const styles = useStyles();

  return (
      <>
      <Skeleton
        animation="wave"
        component="div"
        height={300}
        variant="rect"
        />
        <Skeleton animation="wave" height={50} className={styles.bodyHeader}/>
        <Skeleton animation="wave" height={25} className={styles.body2Indent}/>
        <div className={styles.menucontent}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            <Grid item md={4} sm={6} xs={12}>
                <SkeletonCard button/>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SkeletonCard button/>
            </Grid>
          </Grid>
        </div>
        <SkeletonFooter/>
        </>
  );
};

export const SkeletonDocumentsHomeView = () => {
  const styles = useStyles();

  return (
      <>
        <Skeleton animation="wave" height={50} className={styles.bodyHeader}/>
        <Skeleton animation="wave" height={25} className={styles.body2Indent}/>
        <div className={styles.menucontent}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            <Grid item md={4} sm={6} xs={12}>
                <SkeletonCard button/>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SkeletonCard button/>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
                <SkeletonCard button/>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <SkeletonCard button/>
            </Grid>
          </Grid>
        </div>
        <SkeletonFooter/>
        </>
  );
};
